import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbModal, NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import Swal from 'sweetalert2';


interface member {
  profile: string,
  list: string[],
  name: string,
  designation: string,
  url: string
};

@Component({
  selector: 'app-index-onepage',
  templateUrl: './index-onepage.component.html',
  styleUrls: ['./index-onepage.component.css']
})

/**
 * Onepage Component
 */
export class IndexOnepageComponent implements OnInit {

  constructor(private http: HttpClient, private modalService: NgbModal,
    private offcanvasService: NgbOffcanvas,) { }

  isCondensed = false;
  currentSection = 'home';
  // Set Topbar Option
  Menuoption = 'center';
  Settingicon = true

  name: any = ''
  email: any = ''
  question: any = ''

  memberData1: member[] = [
    {
      profile: "assets/images/client/johanImg.jpg",
      list: ["linkedin"],
      name: "Johan Mulder",
      designation: "Co-founder / Technical Solution Architect",
      url: "https://www.linkedin.com/in/jjmulder/"
    },
    {
      profile: "assets/images/client/marcoImg.jpg",
      list: ["linkedin"],
      name: "Marco Wieling",
      designation: "Co-founder / Functional Solution Architect",
      url: "https://www.linkedin.com/in/marcowieling/"
    },
    {
      profile: "assets/images/client/bogdanImg.png",
      list: ["linkedin"],
      name: "Bogdan Pavel",
      designation: "Team Lead / Full-stack Developer",
      url: "https://www.linkedin.com/in/bogdan-alex-pavel-b7bb13151/"
    },
  ];
  memberData2: member[] = [
    {
      profile: "assets/images/client/koenImg.jpeg",
      list: ["linkedin"],
      name: "Koen Kort",
      designation: "Front-end Developer / UI/UX Designer",
      url: "https://www.linkedin.com/in/koen-kort/"
    },
    {
      profile: "assets/images/client/dragosImg.png",
      list: ["linkedin"],
      name: "Dragos Olaru",
      designation: "BI Developer / OCR Specialist",
      url: "javascript:void(0)"
    },
    {
      profile: "assets/images/client/basImg.jpg",
      list: ["linkedin"],
      name: "Bas Wilson",
      designation: "Back-end Developer / Cloud Engineer",
      url: "https://www.linkedin.com/in/bas-wilson-8b9425146/"
    }
  ];

  simple_pricingData = [
    {
      warning: "Most Used",
      title: "Standard",
      price: "0.01",
      list: ["Up to 100kB size","Unlimited Transformation Steps","Customizable Retention Policy", "Plugin Access"],
      //btn: "Buy Now"
    },
    {
      title: "Large",
      price: "0.07",
      list: ["Up to 1MB size","Unlimited Transformation Steps","Customizable Retention Policy", "Plugin Access"],
      //btn: "Get Started"
    },
    {
      title: "Extra large",
      price: "0.20",
      list: ["Up to 1GB size","Unlimited Transformation Steps","Customizable Retention Policy", "Plugin Access"],
      //btn: "Try It Now"
    }
  ];

  /**
   * Services Data
   */
  servicesData = [
    {
      icon: "uil uil-airplay h2 text-primary",
      title: "Easy To Use",
      description: "We have designed Blocxs One to be an intuitive no-code platform. All the configurations can be done through our logical multi-step wizards."
    },
    {
      icon: "uil uil-file-share-alt h2 text-primary",
      title: "All-Inclusive Document Support",
      description: "Our solution comes with in-build support for a large amount of document types. On top of that, we always give you the possibility to configure your custom document types."
    },
    {
      icon: "uil uil-globe h2 text-primary",
      title: "API Integrations",
      description: "Every Blocxs One functionality is made available through our public API endpoints. This will allow your team to use our services in the best way that suits your needs."
    }
  ];


  ngOnInit(): void {
  }

  sendMail(){

    this.http.post(`https://api.blocxs.one/rest/customer-inquiry`, { name: this.name, email: this.email, question: this.question }).subscribe(data => {
      console.log("Message sent")
      Swal.fire({
        title: 'Messsage sent!',
        text: "Thank you for contacting us! We will get back to you soon.",
        icon: 'success',
        confirmButtonColor: '#2f55d4',
        allowOutsideClick: true
      });
    },
      (error) => {
          console.log(error)
          Swal.fire({
            title: 'Something went wrong!',
            icon: 'error',
            allowOutsideClick: true,
            confirmButtonColor: '#2f55d4',
            text: "Please try again later or use one of the other methods to contact us!"
          });
      })
  }

  mapView(mapcontent) {
    this.modalService.open(mapcontent, { windowClass: 'dark-modal', size: 'lg', centered: true })
  }

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }
  openTrialModal(content: any) {
    this.modalService.open(content, { centered: true });
  }

  /**
   * Toggle menu
   */
  toggleMenu() {
    this.isCondensed = !this.isCondensed;
    if (this.isCondensed) {
      document.getElementById('navigation').style.display = 'block';
    } else {
      document.getElementById('navigation').style.display = 'none';
    }
  }

  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.getElementById('topnav').classList.add('nav-sticky');
    } else {
      document.getElementById('topnav').classList.remove('nav-sticky');
    }

    if (document.body.scrollTop > 100 ||
      document.documentElement.scrollTop > 100) {
      document.getElementById('back-to-top').style.display = 'inline';
    } else {
      document.getElementById('back-to-top').style.display = 'none';
    }
  }

  /**
  * Section changed method
  * @param sectionId specify the current sectionID
  */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }


  // Demo Offcanvas
  openright(content: TemplateRef<any>) {  
    this.offcanvasService.open(content, { position: 'end' });
  }

}
