<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" [ngxScrollTo]="'#home'">
        <img src="assets/images/blocxs-one-low-resolution-logo-color-on-transparent-background-grey-dark-blue.png" height="50" alt="">
      </a>
    </div>
      <!--Login button Start-->
      <ul class="buy-button list-inline mb-0" *ngIf="Settingicon == true">
        <li class="list-inline-item mb-0">
          <a href="https://app.blocxs.one/" class="btn btn-primary">Log In</a>
        </li>
      </ul>
      <!--Login button End-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu">
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'home'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#home'">Home</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'feature'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#feature'">Feature</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'pricing'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#pricing'">Price</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'team'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#team'">Team</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'contact'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#contact'">Contact</a>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">
  <!-- Hero Start -->
<section class="bg-home d-flex align-items-center" 
style="padding-bottom: 50px;background: url('assets/images/saas/home-shape.png') center center; height: auto;" id="home">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
      <div class="title-heading margin-top-100">
        <h1 class="heading mb-3">We will convert and deliver your electronic documents just as your business partners want to receive them.</h1>
        <p class="para-desc mx-auto text-muted">Just tell us what you can send and what they can receive. We will handle the rest.</p>
          <div class="row justify-content-center">
            <div class="mt-4 pt-2">
              <a href="https://app.blocxs.one/" class="btn btn-primary" style="margin-right: 20px">Get Started<i class="mdi mdi-chevron-right"></i></a>
              <a href="#about" class="btn btn-primary">Watch Demo<i class="mdi mdi-chevron-right"></i></a>
            </div>
          </div>
      </div>

      <div class="home-dashboard">
        <img src="assets/images/saas/15-inch-macbook-pro-retina (1).png" alt="" class="img-fluid">
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="section bg-light mt-0 mt-md-5">
<div class="container">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">Our Trusted Clients</h4>
        <p class="text-muted para-desc mx-auto mb-0"> <span class="text-primary fw-bold">Blocxs One</span> provides its services to a diverse client base ranging from small to large and medium sized companies</p>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
  <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"></app-clients-logo>
</div>
<!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Feature Start -->
<section class="section"  id="feature">
<div class="container">
  <app-services [servicesData]="servicesData" service_class="features feature-primary"></app-services>
</div>
<!--end container-->

<div class="container mt-100 mt-60">
  <div class="row align-items-center">
    <div class="col-lg-7 col-md-6">
      <img src="assets/images/saas/bp-screenshot-1.png" class="img-fluid shadow rounded" alt="">
    </div>
    <!--end col-->

    <div class="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div class="section-title ms-lg-5">
        <h4 class="title mb-4">Manage All Your Communication Channels In One Place</h4>
        <p class="text-muted">Instead of having to develop separate communication channels for each business partner, <span class="text-primary fw-bold">Blocxs One</span>
           allows you to easily configure, reuse and maintain everything you need in terms of communicating your electronic documents, 
           all under the umbrella of one platform.</p>
        <ul class="list-unstyled text-muted">
          <li class="mb-1"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Eliminate development and maintenance efforts</li>
          <li class="mb-1"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Optimized cost reduction</li>
        </ul>
        <!-- <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
            class="uil uil-angle-right-b"></i></a> -->
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->
</div>
<!--end container-->

<!-- <div class="container mt-100 mt-60">
  <div class="row align-items-center">
    <div class="col-lg-5 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div class="section-title me-lg-5">
        <h4 class="title mb-4">Plugins & Connectors</h4>
        <p class="text-muted">We offer a wide array of plugins and connectors to make sure that all your document streams can be easily integrated into
           <span class="text-primary fw-bold">BlocxsOne</span>, from where we can process and deliver them to your recipients. What we offer
          can range from pulling documents from an FTP connected folder to processing entire mailbox attachements and many more.
        </p>
        <ul class="list-unstyled text-muted">
          <li class="mb-1"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Highly accessible integrations</li>
          <li class="mb-1"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Configurable and tailored to your needs</li>
        </ul>
      </div>
    </div>
    <div class="col-lg-7 col-md-6 order-1 order-md-2">
      <img src="assets/images/saas/connector-plugin-ss.png" class="img-fluid shadow rounded" alt="">
    </div>
  </div>
</div> -->

<div class="container mt-100 mt-60">
  <div class="row align-items-center">
    <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div class="section-title me-lg-5">
        <h4 class="title mb-4">Plugins & Connectors</h4>
        <p class="text-muted">We provide a wide array of plugins and connectors to make sure that all your document streams can be easily integrated into
           <span class="text-primary fw-bold">Blocxs One</span> so that we can process and deliver them to your recipients. What we offer
          can range from pulling documents from an FTP connected folder to processing entire mailbox attachments and many more.
        </p>
        <ul class="list-unstyled text-muted">
          <li class="mb-1"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Highly accessible integrations</li>
          <li class="mb-1"><span class="text-primary h5 me-2"><i
                class="uil uil-check-circle align-middle"></i></span>Configurable and tailored to your needs</li>
        </ul>
      </div>
    </div>
    <div class="col-lg-5 col-md-6 order-1 order-md-2">
      <img src="assets/images/illustrator/email-capture.svg" alt=""> <!--  app_development_SVG.svg  envelope.svg  -->
    </div>
  </div>
</div>

</section>
<section class="section" id="pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Pay-as-You-Go</h4>
          <p class="text-muted para-desc mb-0 mx-auto">With <span
              class="text-primary fw-bold">Blocxs One</span> you pay based on how much you use the product. With prices that go as low as 0.01€ per document, we will transform, deliver and store all your electronic documents.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <app-simple-pricing [simple_pricingData]="simple_pricingData"></app-simple-pricing>
    <!--end row-->
  </div>
  <!--end container-->
<!--end container-->

<div class="container mt-100 mt-60">
  <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
    <div class="row align-items-end">
      <div class="col-md-8">
        <div class="section-title text-md-start text-center">
          <h4 class="title mb-3 text-white title-dark">Start your free 1 month trial today</h4>
          <p class="text-white-50 mb-0">Have a look at our platform and let us set you up with the best communication tool you have used so far.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 mt-sm-0">
        <div class="text-md-end text-center">
          <a href="https://app.blocxs.one/" class="btn btn-light">Get Started</a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
</div>
</section>
<section class="section" id="team">
<div class="container mt-100 mt-60">
<div class="row">
  <div class="col-12 text-center">
    <div class="section-title mb-4 pb-2">
      <h4 class="title mb-4">Our Greatest Minds</h4>
      <p class="text-muted para-desc mx-auto mb-0">We take pride in our diverse team of engineers and problem solvers who will ensure that your needs are always on the top of their to do list.</p>
    </div>
  </div>
  <!--end col-->
</div>
<!--end row-->

<div class="row">
  <div class="col-lg-4 col-md-4 mt-4 pt-2" *ngFor="let data of memberData1">
    <div class="card team text-center bg-transparent border-0">
      <div class="card-body p-0">
        <div class="position-relative">
          <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
          <ul class="list-unstyled mb-0 team-icon">
            <li class="list-inline-item"><a href="{{data.url}}" class="btn btn-primary btn-pills btn-sm btn-icon ms-1"
                *ngFor="let item of data.list">
                <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
          <!--end icon-->
        </div>
        <div class="content pt-3 pb-3">
          <h5 class="mb-0"><a href="{{data.url}}" class="name text-dark">{{data.name}}</a></h5>
          <!-- <small class="designation text-muted">{{data.designation}}</small> -->
        </div>
      </div>
    </div>
  </div>
  <!--end row-->
</div>
<div class="row">
  <div class="col-lg-4 col-md-4 mt-4 pt-2" *ngFor="let data of memberData2">
    <div class="card team text-center bg-transparent border-0">
      <div class="card-body p-0">
        <div class="position-relative">
          <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
          <ul class="list-unstyled mb-0 team-icon">
            <li class="list-inline-item"><a href="{{data.url}}" class="btn btn-primary btn-pills btn-sm btn-icon ms-1"
                *ngFor="let item of data.list">
                <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
              </a></li>
          </ul>
          <!--end icon-->
        </div>
        <div class="content pt-3 pb-3">
          <h5 class="mb-0"><a href="{{data.url}}" class="name text-dark">{{data.name}}</a></h5>
          <!-- <small class="designation text-muted">{{data.designation}}</small> -->
        </div>
      </div>
    </div>
  </div>
  <!--end row-->
</div>
<!--end container-->
</div>
</section>

<!--end container-->
</div>

<!--end container-->
<!-- Contact Start -->
<section class="section pb-0" id="contact">
  <div class="container">
    <div class="row">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Contact us!</h4>
          <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Need to get in touch with us? You can either fill in the form with your question or reach us through phone or email information below.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6 mt-4 pt-2 order-2 order-md-1">
        <div class="card rounded shadow border-0" data-aos="fade-up" data-aos-duration="1000">
          <div class="card-body">
            <div class="custom-form">
              <div id="message"></div>
              <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Your Name <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="user" class="fea icon-sm icons"></i-feather>
                        <input [(ngModel)]="name" name="name" id="name" type="text" class="form-control ps-5" placeholder="First Name :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-6">
                    <div class="mb-3">
                      <label class="form-label">Your Email <span class="text-danger">*</span></label>
                      <div class="form-icon position-relative">
                        <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                        <input [(ngModel)]="email" name="email" id="email2" type="email" class="form-control ps-5"
                          placeholder="Your email :">
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                  <div class="col-lg-12">
                    <div class="mb-3">
                      <label class="form-label">Question</label>
                      <div class="form-icon position-relative">
                        <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                        <textarea [(ngModel)]="question" name="comments" id="comments" rows="4" class="form-control ps-5"
                          placeholder="Your Message :"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end row-->
                <div class="row">
                  <div class="col-sm-12 text-center">
                    <div class="d-grid">
                      <input type="submit" id="submit" [disabled]="name === '' || email === '' || question === ''" name="send" (click)="sendMail()" class="submitBnt btn btn-primary"
                        value="Send Message">
                      <div id="simple-msg"></div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </form>
              <!--end form-->
            </div>
            <!--end custom-form-->
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 pt-2 order-1 order-md-2">
        <div class="title-heading ms-lg-4">
          <h4 class="mb-4" data-aos="fade-up" data-aos-duration="1000">Contact Details</h4>
          <p class="text-muted" data-aos="fade-up" data-aos-duration="1400">Always humans, never bots.</p>
          <div class="card border-0 bg-transparent">
            <div class="card-body p-0">
              <div class="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                <div class="icon">
                  <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
                </div>
                <div class="content overflow-hidden d-block">
                  <h6 class="fw-bold mb-0">Email</h6>
                  <a href="mailto:contact@example.com" class="text-primary">support@blocxs.com</a>
                </div>
              </div>
            </div>
          </div>

          <div class="card border-0 bg-transparent">
            <div class="card-body p-0">
              <div class="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1400">
                <div class="icon">
                  <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
                </div>
                <div class="content overflow-hidden d-block">
                  <h6 class="fw-bold mb-0">Phone</h6>
                  <a href="tel:+152534-468-854" class="text-primary">+31348234000</a>
                </div>
              </div>
            </div>
          </div>

          <div class="card border-0 bg-transparent">
            <div class="card-body p-0">
              <div class="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1600">
                <div class="icon">
                  <i-feather name="linkedin" class="fea icon-m-md text-dark me-3"></i-feather>
                </div>
                <div class="content overflow-hidden d-block">
                  <h6 class="fw-bold mb-0">LinkedIn</h6>
                  <a  href="https://www.linkedin.com/company/blocxs/mycompany/" data-type="iframe"
                    class="video-play-icon text-primary">Connect with us</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end contact-->

  <div class="container-fluid mt-100 mt-60">
    <div class="row">
      <div class="col-12 p-0">
        <div class="map">
          <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2434.9277163016345!2d4.897711397525553!3d52.38986067373543!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c6084b04fe7159%3A0x959ba5c4412c2692!2sAsterweg%2017A2%2C%201031%20HL%20Amsterdam!5e0!3m2!1sen!2snl!4v1680968591207!5m2!1sen!2snl"
          style="border:0" allowfullscreen></iframe>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
<i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
  <app-footer></app-footer>
