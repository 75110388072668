import { Component, OnInit, Input } from '@angular/core';

interface client {
  image: string;
};

@Component({
  selector: 'app-clients-logo',
  templateUrl: './clients-logo.component.html',
  styleUrls: ['./clients-logo.component.css']
})
export class ClientsLogoComponent implements OnInit {

  @Input() brand_class: string | undefined;
  /**
   * Clients Logo
   */
   clients_logo: client[] = [
    {
      image: "assets/images/client/isero-logo-1-removebg-preview.png"
    },
    {
      image: "assets/images/client/polvo-logo-1-removebg-preview.png"
    },
    {
      image: "assets/images/client/ferney-logo-1-removebg-preview.png"
    },
    {
      image: "assets/images/client/tricorp-logo-1-removebg-preview.png"
    },
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
