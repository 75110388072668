<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" [ngxScrollTo]="'#home'">
        <img src="assets/images/logo-dark.png" height="24" alt="">
      </a>
    </div>
      <!--Login button Start-->
      <ul class="buy-button list-inline mb-0" *ngIf="Settingicon == true">
        <li class="list-inline-item mb-0">
            <a href="javascript:void(0)" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" (click)="openright(content)">
                <div class="btn btn-icon btn-pills btn-soft-primary" id="settingbtn"><i-feather name="settings" class="fea icon-sm"></i-feather></div>
              </a>
        </li>
        
        <li class="list-inline-item ps-2 mb-0">
            <a href="https://1.envato.market/landrick" target="_blank">
                <div class="btn btn-icon btn-pills btn-primary" id="settingbtn"><i-feather name="shopping-cart" class="fea icon-sm"></i-feather></div>
              </a>
        </li>
      </ul>
      <!--Login button End-->
    <!-- End Logo container-->
    <div class="menu-extras">
      <div class="menu-item">
        <!-- Mobile menu toggle-->
        <a class="navbar-toggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
          <div class="lines">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
        <!-- End mobile menu toggle-->
      </div>
    </div>

    <div id="navigation">
      <!-- Navigation Menu-->
      <ul class="navigation-menu">
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'home'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#home'">Home</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'service'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#service'">Feature</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'testimonial'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#testimonial'">Review</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'pricing'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#pricing'">Price</a>
        </li>
        <li class="has-submenu" [ngClass]="{'active':currentSection === 'contact'}">
          <a href="javascript: void(0);" [ngxScrollTo]="'#contact'">Contact</a>
        </li>
      </ul>
      <!--end navigation menu-->
    </div>
    <!--end navigation-->
  </div>
  <!--end container-->
</header>
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

<!-- Hero Start -->
<section class="bg-home d-flex align-items-center"
  style="background: url('assets/images/saas/home-shape.png') center center; height: auto;" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
        <div class="title-heading margin-top-100">
          <h1 class="heading mb-3">Build fast, released quickly.</h1>
          <p class="para-desc mx-auto text-muted">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap v5 html page.</p>
          <div class="mt-4 pt-2">
            <a href="#about" class="btn btn-primary">Get Started<i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>

        <div class="home-dashboard">
          <img src="assets/images/saas/home.png" alt="" class="img-fluid">
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="section bg-light mt-0 mt-md-5">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Trusted Clients</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center mt-4 pt-2"></app-clients-logo>
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Feature Start -->
<section class="section">
  <div class="container">
    <app-services [servicesData]="servicesData" service_class="features feature-primary"></app-services>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6">
        <img src="assets/images/saas/classic02.png" class="img-fluid shadow rounded" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">Great Product Analytics With Real Problem</h4>
          <p class="text-muted">Due to its widespread use as filler text for layouts, non-readability is of great
            importance: human perception is tuned to recognize certain patterns and repetitions in texts. If the
            distribution of letters visual impact.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title me-lg-5">
          <h4 class="title mb-4">Get Notified About Importent Email</h4>
          <p class="text-muted">This prevents repetitive patterns from impairing the overall visual impression and
            facilitates the comparison of different typefaces. Furthermore, it is advantageous when the dummy text is
            relatively realistic.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i
              class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-5 col-md-6 order-1 order-md-2">
        <img src="assets/images/illustrator/app_development_SVG.svg" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>

  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4">Choose The Pricing Plan</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
  
      <app-simple-pricing [simple_pricingData]="simple_pricingData"></app-simple-pricing>
      <!--end row-->
    </div>
    <!--end container-->
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Start your free 2 week trial today</h4>
            <p class="text-white-50 mb-0">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>
        <!--end col-->

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="javascript:void(0)" class="btn btn-light">Get Started</a>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </div>
</section>

<div class="container mt-100 mt-60">
  <div class="row">
    <div class="col-12 text-center">
      <div class="section-title mb-4 pb-2">
        <h4 class="title mb-4">Our Greatest Minds</h4>
        <p class="text-muted para-desc mx-auto mb-0">Start working with <span
            class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
          drive traffic, connect.</p>
      </div>
    </div>
    <!--end col-->
  </div>
  <!--end row-->

  <div class="row">
    <div class="col-lg-3 col-md-6 mt-4 pt-2" *ngFor="let data of memberData">
      <div class="card team text-center bg-transparent border-0">
        <div class="card-body p-0">
          <div class="position-relative">
            <img src="{{data.profile}}" class="img-fluid avatar avatar-ex-large rounded-circle" alt="">
            <ul class="list-unstyled mb-0 team-icon">
              <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-primary btn-pills btn-sm btn-icon ms-1"
                  *ngFor="let item of data.list">
                  <i-feather name="{{item}}" class="fea icon-sm fea-social"></i-feather>
                </a></li>
            </ul>
            <!--end icon-->
          </div>
          <div class="content pt-3 pb-3">
            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">{{data.name}}</a></h5>
            <small class="designation text-muted">{{data.designation}}</small>
          </div>
        </div>
      </div>
    </div>
    <!--end row-->
  </div>
  <!--end container-->


  <!--end container-->
</div>

  <!--end container-->
</section>
  <!-- Contact Start -->
  <section class="section pb-0" id="contact">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4" data-aos="fade-up" data-aos-duration="1000">Get In Touch !</h4>
            <p class="text-muted para-desc mx-auto mb-0" data-aos="fade-up" data-aos-duration="1400">Start working with
              <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to
              generate
              awareness, drive traffic, connect.
            </p>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->

      <div class="row align-items-center">
        <div class="col-lg-5 col-md-6 mt-4 pt-2 order-2 order-md-1">
          <div class="card rounded shadow border-0" data-aos="fade-up" data-aos-duration="1000">
            <div class="card-body">
              <div class="custom-form">
                <div id="message"></div>
                <form method="post" action="php/contact.php" name="contact-form" id="contact-form">
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Your Name <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="user" class="fea icon-sm icons"></i-feather>
                          <input name="name" id="name" type="text" class="form-control ps-5" placeholder="First Name :">
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label class="form-label">Your Email <span class="text-danger">*</span></label>
                        <div class="form-icon position-relative">
                          <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                          <input name="email" id="email2" type="email" class="form-control ps-5"
                            placeholder="Your email :">
                        </div>
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label class="form-label">Comments</label>
                        <div class="form-icon position-relative">
                          <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                          <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                            placeholder="Your Message :"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end row-->
                  <div class="row">
                    <div class="col-sm-12 text-center">
                      <div class="d-grid">
                        <input type="submit" id="submit" name="send" class="submitBnt btn btn-primary"
                          value="Send Message">
                        <div id="simple-msg"></div>
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </form>
                <!--end form-->
              </div>
              <!--end custom-form-->
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-lg-7 col-md-6 mt-4 pt-2 order-1 order-md-2">
          <div class="title-heading ms-lg-4">
            <h4 class="mb-4" data-aos="fade-up" data-aos-duration="1000">Contact Details</h4>
            <p class="text-muted" data-aos="fade-up" data-aos-duration="1400">Start working with <span
                class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
              drive traffic, connect.</p>
            <div class="card border-0 bg-transparent">
              <div class="card-body p-0">
                <div class="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1200">
                  <div class="icon">
                    <i-feather name="mail" class="fea icon-m-md text-dark me-3"></i-feather>
                  </div>
                  <div class="content overflow-hidden d-block">
                    <h6 class="fw-bold mb-0">Email</h6>
                    <a href="mailto:contact@example.com" class="text-primary">contact@example.com</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 bg-transparent">
              <div class="card-body p-0">
                <div class="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1400">
                  <div class="icon">
                    <i-feather name="phone" class="fea icon-m-md text-dark me-3"></i-feather>
                  </div>
                  <div class="content overflow-hidden d-block">
                    <h6 class="fw-bold mb-0">Phone</h6>
                    <a href="tel:+152534-468-854" class="text-primary">+152 534-468-854</a>
                  </div>
                </div>
              </div>
            </div>

            <div class="card border-0 bg-transparent">
              <div class="card-body p-0">
                <div class="contact-detail d-flex align-items-center mt-3" data-aos="fade-up" data-aos-duration="1600">
                  <div class="icon">
                    <i-feather name="map-pin" class="fea icon-m-md text-dark me-3"></i-feather>
                  </div>
                  <div class="content overflow-hidden d-block">
                    <h6 class="fw-bold mb-0">Location</h6>
                    <a (click)="mapView(mapcontent)" href="javascript:void(0);" data-type="iframe"
                      class="video-play-icon text-primary">View on Google map</a>

                    <ng-template #mapcontent let-modal>
                      <div class="modal-header">
                        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
                          height="450" width="750">
                        </iframe>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end contact-->

    <div class="container-fluid mt-100 mt-60">
      <div class="row">
        <div class="col-12 p-0">
          <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin"
              style="border:0" allowfullscreen></iframe>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
</div>