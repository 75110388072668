import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexOnepageComponent } from './core/components/index-onepage/index-onepage.component';


const routes: Routes = [

  { path: '', component: IndexOnepageComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: "top",
  scrollOffset: [0, 0],
  // Enable scrolling to anchors
  anchorScrolling: "enabled"})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
